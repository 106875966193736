import React from 'react';
import {
  Button,
  Container,
  Flex,
  Heading,
  Text,
} from '@talent-garden/react-components';
import type { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import { Image } from '@/components/image';
import { Meta } from '@/components/meta';
import styles from '@/styles/404.module.css';
import type { NextPageWithLayout } from '@/types/next';

export const getStaticProps: GetStaticProps = async ({ locale = 'en' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['404'])),
    },
  };
};

const PageNotFound: NextPageWithLayout = () => {
  const router = useRouter();
  const { t } = useTranslation(['404']);
  return (
    <Container className={styles.container}>
      <Meta title={`${t('title')} | Learn`} noFollow />
      <Flex direction="column" className={styles.main} horizontalAlign="center">
        <Flex
          direction="column"
          className={styles.content}
          horizontalAlign="center"
          rowGap={40}
        >
          <Flex direction="column" horizontalAlign="center" rowGap={16}>
            <Image
              src="/images/error_image.png"
              alt="Error image"
              width={200}
              height={200}
            />
            <Heading as="h2" variant="l" textAlign="center">
              {t('title')}
            </Heading>
            <Text variant="m" textAlign="center">
              {t('description')}
            </Text>
            <Button
              as="button"
              capitalize
              dimension="regular"
              kind="primary"
              target="_blank"
              onClick={() => router.back()}
            >
              {t('back')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
};

PageNotFound.getLayout = (page: React.ReactNode) => page;

export default PageNotFound;
